import { createInertiaApp, router } from '@inertiajs/vue3';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { i18nVue } from 'laravel-vue-i18n';
import posthog from 'posthog-js';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import { createSSRApp, h } from 'vue';
import { ZiggyVue } from 'ziggy-js';
import '../css/app.scss';
import './bootstrap';
import { update } from '@intercom/messenger-js-sdk';
import Intercom from '@intercom/messenger-js-sdk';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Calendarlink';
const intercomAppId = 'b7tmb34a';

import.meta.glob([
  '../images/**',
]);

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const vueApp = createSSRApp({ render: () => h(App, props) });

    if (process.env.NODE_ENV === 'production') {
      let identified = false;
      router.on('navigate', (event) => {
        if (posthog.get_distinct_id() === undefined) {
          posthog.init('phc_KlH1dSBrzmSkQNYyKIRRsZoiQr7OaJimdlfIdeP0JM7', {
            api_host: 'https://eu.posthog.com',
            disable_session_recording: !!document.getElementById('disableTracking'),
            session_recording: {
              recordCrossOriginIframes: true,
            },
            opt_in_site_apps: true,
          });
        }

        if (document.getElementById('disableTracking') === null) {
          let user_id = null;
          let email = null;
          let name = null;
          let current_team = null;

          posthog.capture('$pageview');

          if (event.detail.page.props.auth.user !== null) {
            user_id = event.detail.page.props.auth.user.id;
            email = event.detail.page.props.auth.user.email;
            name = event.detail.page.props.auth.user.name;
            current_team = event.detail.page.props.auth.user.current_team.name;
          }

          // Boot Intercom
          Intercom({
            app_id: intercomAppId,
          });

          doTrack(event.detail.page.url, document.title);

          if (!identified && email !== null && name !== null && current_team !== null) {
            identifyUser(user_id, email, name, current_team);
            identified = true;
          }
        }
      });

      const doTrack = (page_location, page_title) => {
        gtag('consent', 'default', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          security_storage: 'granted',
          wait_for_update: 2000,
        });
        gtag('js', new Date());
        gtag('config', 'G-QQQGNT3NMQ');

        update({
          page_title: page_title,
        });
      };

      const identifyUser = (user_id, email, name, current_team) => {
        posthog.identify(
          user_id,
          {
            email: email,
            name: name,
            current_team: current_team,
          },
        );

        // Update Intercom user
        update({
          email: email,
          user_id: user_id,
          name: name,
          user_hash: document.head.querySelector('[name=intercom-identify]').content,
        });

        Sentry.setUser({
          id: user_id,
        });
      };

      Sentry.init({
        app: vueApp,
        dsn: 'https://9e0000db867e42efbb4f2ff45cbaece9@sentry.qodegroup.com/8',
        environment: 'production',
        tracesSampleRate: 1.0,
        trackComponents: true,
        logErrors: true,
        ignoreErrors: [
          'Non-Error promise rejection captured',
          'ResizeObserver loop completed with undelivered notifications.',
        ],
        integrations: [
          new BrowserTracing(),
          new posthog.SentryIntegration(posthog, 'qode', '8'),
        ],
      });
    }

    let locale = window.navigator.language || window.navigator.userLanguage;
    locale = locale.split('-')[0];

    vueApp.use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(PrimeVue)
      .use(i18nVue, {
        lang: locale || 'en',
        resolve: async lang => {
          const langs = import.meta.glob('../../lang/*.json');
          return await langs[`../../lang/${lang}.json`]();
        },
      })
      .mixin({ directives: { Tooltip } })
      .mount(el);
  },
  progress: {
    color: '#4CCEF6',
  },
});